<template>
  <MDBNavbar expand="lg" light bg="white" class="d-flex justify-content-between ps-4 pe-4">
    <h4 class="m-1">{{ $t(`HEADER.${path[(path.length -1 )].toUpperCase().replaceAll("-","")}`) }}</h4>
    <nav aria-label="breadcrumb">
      <MDBBreadcrumb>
        <MDBBreadcrumbItem v-for="(route , l) in path" :key="l">
          <a href="#"> {{ $t("HEADER." + (route.toUpperCase().replaceAll("-",""))) }}</a>
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
    </nav>
  </MDBNavbar>
</template>

<script>
import { MDBNavbar, MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-vue-ui-kit';
import { useRoute } from 'vue-router';
export default {
  name: "Breadcrumbs",
  components: {
    MDBNavbar,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
  },
  setup() {
    const route = useRoute().path;
    const path = route.split("/").filter(r => r!="");
    return {
      path
    };
  },
};
</script>