<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("TECHNOLOGY.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("TECHNOLOGY.EDIT-ITEM") }}</MDBCardHeader>
        <MDBRow class="mb-3 mt-3 text-start" v-if="Action == 'insert'">
          <MDBCol >
            <h5> {{ $t("COMMON.SELECTLANGUAGE") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" v-if="action == 'insert'">
          <div class="mb-3">
            <div>
              <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.LANGUAGE') }}<span class="text-danger">*</span></label>  
            </div>
            <template v-for="(lang ,k) in languages" :key="k">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-bind:id="`${Action}-lang-${lang.lang}`" :value="lang.lang" v-model="Form.lang.value">
                <label class="form-check-label" v-bind:for="`${Action}-lang-${lang.lang}`">
                  {{ $t(`LANG.${lang.lang}`) }}
                </label>
              </div>
            </template>
            <span class="text-danger">{{ langError }}</span>
          </div>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5> {{ $t("COMMON.SETCATEGORY") }}<span class="text-danger">*</span></h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <template v-for="(main, k) in getTechnologyCategory" :key="k">
            <MDBCol md="3">
              <ul class="border p-2 category" v-if="typeof Form.lang.value != 'undefined'">
                <li>主：{{ main.name[Form.lang.value].name }}</li>
                <template v-if="(main.sub.length > 0)">
                  <template v-for="(sub, k) in main.sub" :key="k">
                    <ul class="category">
                      <li>
                        <span class="me-2">次：{{ sub.name[Form.lang.value].name }}</span>
                      </li>
                      <ol class="chapter">
                        <template  v-if="(sub.sub.length > 0)">
                          <template  v-for="(technology, index) in sub.sub" :key="index">
                            <li>
                              <input class="form-check-input" type="radio" :id="`${action}-${technology.uuid}`" :value="technology.uuid" v-model="Form.category.value">
                              <label class="form-check-label" :for="`${action}-${technology.uuid}`">
                                {{ technology.name[Form.lang.value].name }}
                              </label>
                            </li>
                          </template>
                        </template>
                      </ol>
                    </ul>
                  </template>
                </template>
              </ul>
            </MDBCol>
          </template>
          <span class="text-danger">{{ categoryError }}</span>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5>{{ $t("COMMON.SETPRODUCT") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="12">
            <MDBRow class="mb-3">
              <MDBCol md="6">
                <MDBRow>
                  <MDBCol md="12">
                    <div class="mb-3">
                      <template  v-for="(item ,k) in Products.filter(item=>item.lang==Form.lang.value)" :key="k">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-bind:id="`${Action}-products-${k}`" :value="item.uuid" v-model="Form.products.value">
                          <label class="form-check-label" v-bind:for="`${Action}-products-${k}`">
                            {{ item.name }}
                          </label>
                        </div>
                      </template>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol >
            <h5> {{ $t("COMMON.SETVIDEO") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <div>
              <div>
                <a href="javascript:void(0)" @click="AddViedo"><MDBIcon icon="plus" iconStyle="fas" />{{ $t(`TECHNOLOGY.ADD-VIDELS`) }}</a>
              </div>
            </div>
            <template v-for="(download,k) in Form.videos.value" :key="k">
              <MDBRow class="mb-3" v-if="(typeof Form.videos.value[k] != 'undefined')">
                <MDBCol md="3">
                  <MDBInput
                    :label="$t(`COMMON.VIDEONAME`)"
                    type="text"
                    v-model="Form.videos.value[k].name"
                  />
                </MDBCol>
                <MDBCol md="8">
                  <MDBInput
                    :label="$t(`COMMON.VIDEOLINK`)"
                    type="text"
                    v-model="Form.videos.value[k].link"
                  />
                </MDBCol>
                <MDBCol md="1">
                  <a @click="DeleteViedeo(k)" data-bs-toggle="tooltip" :title="$t('TECHNOLOGY.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
                    <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
                  </a>
                </MDBCol>
              </MDBRow>
            </template>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol >
            <h5> {{ $t("COMMON.SETDOWNOLAD") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <div>
              <a href="javascript:void(0)" @click="AddDownload"><MDBIcon icon="plus" iconStyle="fas" />{{ $t(`PRODUCT-LIST.ADD-PRODUCTDOWNLOADS`) }}</a>
            </div>
            <template v-for="(download,k) in Form.downloads.value" :key="k">
              <MDBRow class="mb-3" v-if="(typeof Form.downloads.value[k] != 'undefined')">
                <MDBCol md="3">
                  <MDBInput
                    :label="$t(`COMMON.FILENAME`)"
                    type="text"
                    v-model="Form.downloads.value[k].name"
                  />
                </MDBCol>
                <MDBCol md="8">
                  <MDBInput
                    :label="$t(`COMMON.FILELINK`)"
                    type="text"
                    v-model="Form.downloads.value[k].link"
                  />
                </MDBCol>
                <MDBCol md="1">
                  <a @click="DeleteDownload(k)" data-bs-toggle="tooltip" :title="$t('PRODUCT-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
                    <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
                  </a>
                </MDBCol>
              </MDBRow>
            </template>
          </MDBCol> 
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol>
            <h5 >{{ $t("COMMON.SETCONTENT") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" >
          <MDBCol md="12">
            <MDBRow class="g-3">
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.NAME') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.NAME')" v-model="Form.name.value">
                  <span class="text-danger">{{ nameError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.SORT') }}<span class="text-danger">*</span></label>
                  <input type="number" class="form-control" :placeholder="$t('COMMON.SORT')" v-model="Form.sort.value">
                  <span class="text-danger">{{ sortError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.CONTENT') }}<span class="text-danger">*</span></label>
                  <Ckeditor5 label="" v-model="Form.content.value"></Ckeditor5>
                  <span class="text-danger">{{ contentError }}</span>
                </div>
              </MDBCol>
              <div class="row mt-3">
                <label class="form-label text-dark" >關鍵字</label>
                <template v-for="(value,name) in Form.seo.value" :key="name">
                  <div class="col-md-4">
                    {{ $t(`SEO.${name}`) }} :<input type="text" class="form-control mb-1" :placeholder="$t(`SEO.${name}`)" v-model="Form.seo.value[name]">
                  </div>
                </template>
              </div>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBBtn color="primary" type="submit"  v-if="action == 'insert'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .category {
    list-style: none;
  }
</style>

<script>
import { MDBInput, MDBRow, MDBBtn, MDBCol, MDBIcon, MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import Ckeditor5 from '@/components/Plugin/Ckeditor5';
import { mapGetters } from "vuex";

export default {
  name: "TechnologyForm",
  components: {
    MDBInput,
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    Ckeditor5,
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  inject: ["Images","Products"],
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const languages = i18nService.languages;
    const SubmitLoading = ref(false);

    const basicSchema = {
      lang: yup.string().required().label(i18n.t("COMMON.LANGUAGE")),
      name: yup.string().required().label(i18n.t("COMMON.NAME")),
      category: yup.string().required().label(i18n.t("HEADER.TECHNOLOGYCATEGORY")),
      content: yup.string().required().label(i18n.t("COMMON.CONTENT")),
      videos: yup.array().label(i18n.t("COMMON.PRODUCTDOWNLOADS")),
      downloads: yup.array().label(i18n.t("COMMON.PRODUCTDOWNLOADS")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
      products: yup.array().label(i18n.t("COMMON.SETCAROUSEL")),
      seo: yup.object().label("關鍵字"),
    };

    let ExtendSchema = {};
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        products: [],
        videos: [],
        downloads: [],
        lang: i18nService.getActiveLanguage(),
        sort: 0,
        name: "",
        content: "",
        seo: {
          title:'',
          keyword:'',
          content:'',
        }
      }
    });

    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: category, errorMessage: categoryError } = useField('category');
    const { value: name, errorMessage: nameError } = useField('name');
    const { value: content, errorMessage: contentError } = useField('content');
    const { value: videos, errorMessage: videosError } = useField('videos');
    const { value: downloads, errorMessage: downloadsError } = useField('downloads');
    const { value: sort, errorMessage: sortError } = useField('sort');
    const { value: products, errorMessage: productsError } = useField('products');
    const { value: seo, errorMessage: seoError } = useField('seo');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Technology", values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Technology",props.uuid ,values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    const Form = {
      lang,
      category,
      name,
      content,
      videos,
      downloads,
      sort,
      products,
      seo,
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    const AddViedo = function(){
      Form.videos.value.push({
        name: "",
        link: ""
      })
    };

    const DeleteViedeo = function(index){
      Form.videos.value = Form.videos.value.filter(function(item,key) {
        return key !== index;
      })
    };

    const AddDownload = function(){
      Form.downloads.value.push({
        name: "",
        link: "",
      })
    };

    const DeleteDownload = function(index){
      Form.downloads.value = Form.downloads.value.filter(function(item,key) {
        return key !== index;
      })
    };

    return {
      Action,
      Model,
      SubmitLoading,
      Form,
      langError,
      categoryError,
      nameError,
      contentError,
      videosError,
      downloadsError,
      sortError,
      productsError,
      seoError,
      Submit,
      resetForm,
      FormUUid,
      languages,
      Close,
      AddViedo,
      DeleteViedeo,
      AddDownload,
      DeleteDownload
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  computed: {
    ...mapGetters([
      "getTechnologyCategory"
    ])
  },
  methods: {
    GetDetail() {
      this.resetForm();
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Technology", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "category":
                      this.Form.category.value = response.data.data.category[0].uuid;
                    break;
                    case "videos":
                      this.Form[k].value = response.data.data[k];
                    break;
                    case "downloads":
                      this.Form[k].value = response.data.data[k];
                    break;
                    case "products":
                      this.Form.products.value = response.data.data.products.map(item => item.uuid);
                    break;
                    case "seo":
                      this.Form[k].value = response.data.data[k];
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  }
};
</script>