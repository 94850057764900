<template>
  <div class="PageLoaging">
    <div class="spinner-border Loading" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scope>
  .PageLoaging {
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    z-index: 100;
    top: 0;
    left: 0;
    height: 78vh;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .PageLoaging .Loading {
    position: absolute;
    top: 50%;
    padding: unset;
    transition: unset;
    background-image: unset;
    background-color: unset;
    border-width: unset;
  }
</style>

<script>
export default {
  name: "PageLoading"
};
</script>