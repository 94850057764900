<template>
	<MDBTable
		class="table datatable-table "
		:class="{ 'Loading':dataTableLoading }"
		responsive
	>
		<thead>
			<tr>
				<th v-for="(column ,k) in header" scope="col" :key="k" :width="column.width" :class="column.class">
					{{ column.text }}
				</th>
			</tr>
		</thead>
		<tbody>
			<template v-if="tabledata.length > 0 && !dataTableLoading">
				<tr v-for="(row ,k) in tabledata" :key="k" >
					<td scope="row" v-for="(column ,i) in header" :key="i" :class="column.class">
						<slot :name="column.value" :item="row">
							{{ this.HandleData(column.value, row) }}
						</slot>
					</td>
				</tr>	
			</template>
			<tr v-else-if="dataTableLoading">
				<td :colspan="header.length" class="text-center"></td>
			</tr>
			<tr v-else-if="tabledata.length == 0 && !dataTableLoading">
				<td :colspan="header.length" class="text-center">無資料</td>
			</tr>
		</tbody>
		<tfoot>
			<slot name="tfoot" :item="tfoot">
			</slot>
		</tfoot>
	</MDBTable>
	<div class="datatable-pagination" v-if="pagination">
		<div class="datatable-select-wrapper">
			<p class="datatable-select-text">每頁行數:</p>
			<div class="select-wrapper">
				<div class="form-outline">
					<MDBSelect v-model:options="page_limit" v-model:selected="dataTableOptionsItemsPerPage"/>
					<div class="valid-feedback"></div>
					<div class="invalid-feedback"></div>
					<span class="select-arrow"></span>
					<div class="form-notch">
						<div class="form-notch-leading" style="width: 9px;"></div>
						<div class="form-notch-middle" style="width: 0px;"></div>
						<div class="form-notch-trailing"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="datatable-pagination-nav">{{ rowStart }} - {{ rowEnd }} of {{ total }}</div>
		<div class="datatable-pagination-buttons">
			<button type="button" role="button" class="btn btn-link datatable-pagination-button datatable-pagination-left" :disabled="options.page == 1" @click="MovePage('previous')">
				<i class="fa fa-chevron-left"></i>
			</button>
			<button type="button" role="button" class="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface" @click="MovePage('next')" :disabled="options.page == total_page">
				<i class="fa fa-chevron-right"></i>
			</button>
		</div>
	</div>
</template>

<style scope>
	.table.Loading tbody {
		position: relative;
	}
	.table.Loading tbody:before {
		display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: 5px auto;
	}
	.table.Loading tbody:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    content: "";
	}
	.table>:not(caption)>*>* {
		padding: 0.5rem 0.9rem;
	}
	.table.Loading>:not(caption)>*>* {
		padding: 1.4rem 1.4rem;
	}
</style>

<script>
import { MDBTable, MDBSelect } from 'mdb-vue-ui-kit';
import { ref, computed } from "vue";
export default {
  name: "DataTable",
  props:{
		header : {
			type: Object,
			default: function() {
				return {};
			} 
		},
		tfoot : {
			type: Object,
			default: function() {
				return {};
			} 
		},
		tabledata: {
			type: Array,
			default: function() {
				return [];
			} 
		},
		total: {
			type: Number,
			default: 0
		},
		options: {
			type: Object,
			default: function() {
				return {
					page: 1,
					itemsPerPage: 10,
				};
			}
		},
		loading: {
			type: Boolean,
			default: false
		},
		pagination: {
			type: Boolean,
			default: true
		}
  },
  data() {
		return {
			total_page: 1,
		}
  },
  setup(props, { emit }) {

		const dataTableOptions = computed({ 
      get: () => props.options, 
      set: (value) => {
				emit('update:options', value) 
      }
    });
    const dataTableOptionsPage = computed({ 
      get: () => props.options.page, 
      set: (value) => {
				let tmp = props.options;
				tmp.page = value
				emit('update:options', tmp);
      }
    });
		const dataTableOptionsItemsPerPage = computed({
			get: () => props.options.itemsPerPage,
			set: (value) => {
				let tmp = props.options;
				tmp.itemsPerPage = value
				emit('update:options', tmp);
			}
    });
    const dataTableLoading = computed({
			get: () => props.loading,
			set: (value) => {
				emit('update:loading', value);
			}
    });

    const page_limit = ref([
						{ text: 10, value: 10 },
						{ text: 50, value: 50 },
						{ text: 100, value: 100 }
					]);
    const rowStart = ref(0);
    const rowEnd = ref(0);
		return {
			page_limit,
			dataTableOptions,
			dataTableOptionsPage,
			dataTableOptionsItemsPerPage,
			dataTableLoading,
			rowStart,
			rowEnd
		}
  },
  components: {
		MDBTable,
		MDBSelect,
  },
  methods: {
		start: function() { this.rowStart = ((this.options.page -1 ) * this.options.itemsPerPage) + 1},
		end: function() {	
			let cal = (this.options.page * this.options.itemsPerPage);
			this.rowEnd = ((cal < this.total)?cal:this.total);
		},
		HandleData(key, row) {
			let tmp = key.split(".");
			let result = row;
			for (let k in tmp) {
				result = result[tmp[k]];
			}
			return result;
		},
		MovePage(page) {
			if(this.dataTableLoading){
				return false;
			}
			if (typeof page == "number") {
				this.dataTableLoading = true;
				this.dataTableOptionsPage = page;
			}else if (typeof page == "string") {
				switch(page) {
					case "next":
						if (this.dataTableOptionsPage != this.total_page) {
							this.dataTableLoading = true;
							this.dataTableOptionsPage = (this.dataTableOptionsPage+1);
						}
					break;
					case "previous":
						if (this.dataTableOptionsPage != 1) {
							this.dataTableLoading = true;
							this.dataTableOptionsPage = (this.dataTableOptionsPage-1);
						}
					break;
				}
			}
		}
  },
  watch: {
		total: function(val) {
			this.total_page = Math.ceil(val/this.options.itemsPerPage);
			this.start();
			this.end();
		},
		"options.page": function() {
			this.total_page = Math.ceil(this.total/this.options.itemsPerPage);
			this.start();
			this.end();
		},
		"options.itemsPerPage": function() {
			this.total_page = Math.ceil(this.total/this.options.itemsPerPage);
			this.MovePage(1);
		},
		dataTableOptionsPage: function(val) {
			this.MovePage(val);
		},
		tabledata: function() {
			this.dataTableLoading = false;
		}
	}
};
</script>